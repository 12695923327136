import { KarrotLocalCountryCode } from "@karrotmarket-com/core";

export type KarrotLang = "en-US" | "en-CA" | "en-GB" | "ja-JP" | "ko-KR";

export function parseKarrotLang(countryCode: string): KarrotLang {
  switch (countryCode) {
    case KarrotLocalCountryCode.US:
      return "en-US";
    case KarrotLocalCountryCode.UK:
      return "en-GB";
    case KarrotLocalCountryCode.JP:
      return "ja-JP";
    case KarrotLocalCountryCode.KR:
      return "ko-KR";
    case KarrotLocalCountryCode.CA:
      return "en-CA";
    default:
      return "en-US";
  }
}
